// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;


// Import Bootstrap variables for use within theme
@import "bootstrap/scss/functions.scss";
@import "bootstrap/scss/variables.scss";

// Import spacing variables
@import "./variables/spacing.scss";

// Import navigation variables
@import "./variables/navigation.scss";