// 
// Nav
// 

// Add styling for icons used within nav links
.nav,
.sb-sidenav-menu {
    .nav-link .sb-nav-link-icon {
        margin-right: 0.5rem;
    }
}
